import './App.css';
import lenorLogo from './assets/lenor-white.svg';
import addBtn from './assets/add-30.png';
import msgIcon from './assets/message.svg';
import home from './assets/home.svg';
import saved from './assets/bookmark.svg';
import rocket from './assets/rocket.svg'
import globe from './assets/globe.svg';
import sendBtn from './assets/send.svg';
import userIcon from './assets/user-icon.png';
import gptImgLogo from './assets/bot-icon.png';
import { sendMsgToOpenAI } from './servercall';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
// import { useEffect, useRef, useState } from 'react';

import React, { useEffect, useRef, useState } from 'react'; // Added for the LaTeX rendering

////////////////////////////////
// CODE GENERATED BY GPT4 version 1
// import axios from 'axios';
import masterPrompt from './masterPrompt'; // Importing the master prompt



function App() {
  ////////////////////////////
  // State and ref Hooks called unconditionally at the top level of the component
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 800);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([{
    text: "Hi welcome to Lenor! What would you like to learn today?",
    isBot: true,
  }]);
  const msgEnd = useRef(null);

  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth > 800);
    }

    window.addEventListener('resize', handleResize);
    // Clean-up function
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Other useEffect and custom functions here...
  useEffect(() => {
    msgEnd.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);


  const handleSend = async (message = input) => {
    if (!message.trim()) return;
    const userMessage = { text: message, isBot: false };
    setMessages(prev => [...prev, userMessage]);
    setInput('');
  
    const aiResponse = await sendMsgToOpenAI(masterPrompt, [...messages, userMessage]);
    // // For rendering LaTeX by wrapping the AI response in LaTeX delimiters
    // if (!aiResponse.includes('\\[') && !aiResponse.includes('$$')) {
    //   // If not, wrap the response in $$ delimiters
    //   aiResponse = `$$${aiResponse}$$`;
    // }

    const botMessage = { text: aiResponse, isBot: true };
    setMessages(prev => [...prev, botMessage]);
  };


  const handleEnter = async (e) => {
    if (e.key ==='Enter') await handleSend();
  }

  const handleQuery = async(e) => {
    const query_instruction = e.target.getAttribute('value');
    console.log("\n\n\n\n\n\n\n\n\n\nthis is Query " + query_instruction + "\n\n\n\n\n\n\n\n\n\n");
    
    setInput(query_instruction);
    handleSend(query_instruction);
    console.log(messages);
  };
  
  // for rendering LaTeX
  // function Message({ text }) {
  //   useEffect(() => {
  //     // Ensure MathJax processes the new message
  //     if (window.MathJax) {
  //       window.MathJax.typesetPromise();
  //     }
  //   }, [text]);
  
  //   // Render the message text directly
  //   return <span>{text}</span>;
  // }

  // for not rendering LaTeX
  function formatMessageText(text) {
    // Check if the text contains Markdown (this is a simplistic check)
  if (text.includes('**') || text.includes('```')) {
    const html = marked.parse(text);
    let sanitizedHtml = DOMPurify.sanitize(html);
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  } else {
      // Split the text by new line characters and map each line to a span element
      return text.split('\n').map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));
    }
  }

  if (!isDesktop) {
    // Early return for non-desktop users
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontSize: '24px',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '20px'
      }}>
        Lenor is claustrophobic and don't like mobile screens. 
        <br />
        <br />
        Please use computer to visit app.lenorai.com :D
        <br />
        <br />
        Get free premium subscription by testing our beta and sharing feedbacks!
        <br />
        (on computer of course)
      </div>
    );
  }  
  ////////////////////////////////////

  // /// This is to check if the user is using a desktop
  // const [isDesktop, setIsDesktop] = useState(window.innerWidth > 800);

  // useEffect(() => {
  //   function handleResize() {
  //     setIsDesktop(window.innerWidth > 800);
  //   }

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  // if (!isDesktop) {
  //   return (
  //     <div style={{
  //       display: 'flex',
  //       justifyContent: 'center',
  //       alignItems: 'center',
  //       height: '100vh',
  //       fontSize: '24px',
  //       fontWeight: 'bold',
  //       textAlign: 'center',
  //       padding: '20px'
  //     }}>
  //       Lenor is claustrophobia at the moment, 
  //       <br />
  //       only computer screen is big enough for it.
  //       <br />
  //       Please use computer to visit app.lenorai.com
  //       <br />
  //       You can get 1 month free premium subscription by giving feedback at app.lenorai.com!
  //     </div>
  //   );
  // }  
  // ///

  // const [input, setInput] = useState("");
  // const [messages, setMessages] = useState([{
  //   text: "Start your learning journey by saying Hi!",
  //   isBot: true,
  // }]);
  // const msgEnd = useRef(null);
  

  // // Master prompt
  // // const masterPrompt = masterPrompt;

  // useEffect(() => {
  //   msgEnd.current?.scrollIntoView({ behavior: "smooth" });
  // }, [messages]);


  // const handleSend = async (message = input) => {
  //   if (!message.trim()) return;
  //   const userMessage = { text: message, isBot: false };
  //   setMessages(prev => [...prev, userMessage]);
  //   setInput('');
  
  //   const aiResponse = await sendMsgToOpenAI(masterPrompt, [...messages, userMessage]);

  //   // // For rendering LaTeX by wrapping the AI response in LaTeX delimiters
  //   // if (!aiResponse.includes('\\[') && !aiResponse.includes('$$')) {
  //   //   // If not, wrap the response in $$ delimiters
  //   //   aiResponse = `$$${aiResponse}$$`;
  //   // }

  //   const botMessage = { text: aiResponse, isBot: true };
  //   setMessages(prev => [...prev, botMessage]);
  // };


  // const handleEnter = async (e) => {
  //   if (e.key ==='Enter') await handleSend();
  // }

  // const handleQuery = async(e) => {
  //   const query_instruction = e.target.getAttribute('value');
  //   console.log("\n\n\n\n\n\n\n\n\n\nthis is Query " + query_instruction + "\n\n\n\n\n\n\n\n\n\n");
    
  //   setInput(query_instruction);
  //   handleSend(query_instruction);
  //   console.log(messages);
  // };
  
  // // // for rendering LaTeX
  // // function Message({ text }) {
  // //   useEffect(() => {
  // //     // Ensure MathJax processes the new message
  // //     if (window.MathJax) {
  // //       window.MathJax.typesetPromise();
  // //     }
  // //   }, [text]);
  
  // //   // Render the message text directly
  // //   return <span>{text}</span>;
  // // }


  // // for not rendering LaTeX
  // function formatMessageText(text) {
  //   // Split the text by new line characters and map each line to a span element
  //   return text.split('\n').map((line, index) => (
  //     <span key={index}>
  //       {line}
  //       <br />
  //     </span>
  //   ));
  // }

  return (
  <div className="App">
    <div className="sideBar">
      <div className="upperSide">
        <div className="upperSideTop">
          <img src={lenorLogo} alt="Logo" className="logo" style={{ width: '100px', height: 'auto',  marginLeft: '0px'}} />
          <span style={{ 
            fontSize: '0.8em', // Smaller than the surrounding text
            fontWeight: 'bold', // Make it bold
            marginLeft: '5px', // Space it a bit from the logo
            verticalAlign: 'super', // Align it with the top of the logo
            color: '#FFFFFF' // Choose a color that stands out, for example, a gold color
          }}>
            BETA
          </span>
          {/* <span className="brand">EduAI</span> */}
        </div>
        <button className="midBtn" onClick={()=>{window.location.reload()}}><img src={addBtn} alt="new chat" className="addBtn" />New Chat</button>
        <div className="upperSideBottom">
          <a href="https://forms.gle/dFPVTkhY4bVxQoqS8" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button className="query">Share your valuable FEEDBACK + Get 1mo subscription(worth ~$20)</button>
          </a>
          <a href="https://lenor-backend-test-a80d5061f58e.herokuapp.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button className="query">SIGN UP (to keep chat history)</button>
          </a>
          <p style={{ marginTop: '10px', padding: '0.5rem', width: '25rem'}}>
            Please note: Our AI tutor is currently in prototype stage. You may experience occasional errors such as AI not understanding your input, reapeating what you said, and formatting issues. We're actively improving the website. Your patience and feedback are much appreciated. Thank you!
          </p>
        </div>
        {/* Predifined queries */}
        {/* <div className="upperSideBottom">
          <button className="query" onClick={handleQuery} value = "I understand the current topic, let's move to the next topic according to the study plan"><img src={msgIcon} alt="Query" />Move to Next Topic</button>
          <button className="query" onClick={handleQuery} value = "Explain in simpler terms"><img src={msgIcon} alt="Query" />Explain in Simpler Terms</button>
          <button className="query" onClick={handleQuery} value = "Can you give me a hard questions about this topic"><img src={msgIcon} alt="Query" />Quizz Me W/ Hard Questions</button>
        </div> */}
    </div>
    <div className="lowerSide">
      {/* <div className="listItems"><img src={home} alt="" className="listItemsImg"/>Home</div> */}
      <a href="https://lenorai.com" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
        <div className="listItems">
          <img src={home} alt="" className="listItemsImg" />Home
        </div>
      </a>
      {/* <div className="listItems"><img src={saved} alt="" className="listItemsImg"/>Feedback (Thank you so much🙏)</div> */}
      <a href="https://forms.gle/dFPVTkhY4bVxQoqS8" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
        <div className="listItems">
          <img src={saved} alt="" className="listItemsImg" />Feedback (Get Free Subscription!)
        </div>
      </a>
      <div className="listItems"><img src={rocket} alt="" className="listItemsImg"/>Upload a File (coming soon)</div>
      <div className="listItems"><img src={globe} alt="" className="listItemsImg"/>Browse Internet (coming soon)</div>
    </div>
    </div>
  <div className="main">
    <div className="chats">
      {messages.map((message, i) => 
        <div key={i} className={message.isBot?"chat bot":"chat"}>
          <img className='chatImg' src={message.isBot?gptImgLogo:userIcon} alt="" />
          {/* This is for not rendering LaTeX */}
          <p className="txt">{formatMessageText(message.text)}</p> 
          {/* This is for rendering LaTeX */}
          {/* <p className="txt"> */}
            {/* Changed from using formatMessageText to directly passing message.text */}
            {/* <Message text={message.text} />  */}
          {/* </p> */}
        </div>
      )}
      <div ref={msgEnd}></div>
    </div>
    <div className="chatFooter">
      <div className="inp">
      <input type="text" placeholder='Send a Message' value={input} onKeyDown={handleEnter} onChange={(e) => { setInput(e.target.value)}}/>
      <button className="send" onClick={handleSend}><img src={sendBtn} alt="Send" /></button>
      </div>
        <p style={{ color: 'white', fontSize: '1rem', lineHeight: '1.2', marginBottom: '0.5rem' }}>WARNING: Lenor is still under training and may teach incorrect information at the moment.</p>
        <p style={{ color: 'white', fontSize: '1rem', lineHeight: '1.2' , marginBottom: '0.5rem' }}>Please use Lenor on full screen desktop browser.</p>
    </div>
  </div>
</div>

  );
}

export default App;




//////////////////////////////////////////////////////////////////////
// OLD CODE BEFORE HARRY UPDATED IT

// // import './App.css';
// // import gptLogo from './assets/chatgpt.svg';
// // import addBtn from './assets/add-30.png';
// // import msgIcon from './assets/message.svg';
// // import home from './assets/home.svg';
// // import saved from './assets/bookmark.svg';
// // import rocket from './assets/rocket.svg'
// // import sendBtn from './assets/send.svg';
// // import userIcon from './assets/user-icon.png';
// // import gptImgLogo from './assets/chatgptLogo.svg';
// // import { sendMsgToOpenAI } from './servercall';
// // import { useEffect, useRef, useState } from 'react';

// require('./App.css');
// const gptLogo = require('./assets/chatgpt.png');
// const addBtn = require('./assets/add-30.png');
// const msgIcon = require('./assets/message.png');
// const home = require('./assets/home.png');
// const saved = require('./assets/bookmark.png');
// const rocket = require('./assets/rocket.png');
// const sendBtn = require('./assets/send.png');
// const userIcon = require('./assets/user-icon.png');
// const gptImgLogo = require('./assets/chatgptLogo.png');
// const { sendMsgToOpenAI } = require('./servercall');
// const { useEffect, useRef, useState } = require('react');

// function App() {
//   const msgEnd = useRef(null);
//   const[input, setInput] = useState("");
//   const[knowledgelevel, setKnowledgeLevel] = useState("");
//   const[levelandTopic, setLevelandTopic] = useState(true);
//   const[practiceQuestionsTriggered, setTrigger] = useState(true);
//   const[firsttime, setFirstTime] = useState(true);
//   const [messages, setMessages] = useState([{
//     text: "Hi I am Lenor, designed to help you study, what would you like to study today?",
//     isBot: true,
//   }]);

//   useEffect(() => {
//     msgEnd.current.scrollIntoView();
//   },[messages]);


//   const handleSend = async() => {
//     const text = input;
//     setInput('');
//     setMessages([
//       ...messages,
//       {text, isBot: false}
//     ]);
//     try {   
//       if (levelandTopic) {
//         setMessages([
//           ...messages,
//           { text, isBot: false},
//           {
//             text: "Are you studying IB or 'A' Level? Which Topic would you like to learn?",
//             isBot: true,
//           },
//         ]);
//         setLevelandTopic(false);
//       } else if (!levelandTopic && firsttime) {
//       setKnowledgeLevel(text);
//       const text2 = text + "Can you provide me with the content required as well as step by step instructions on how to study this topic?"
//       const res = await sendMsgToOpenAI(text2);
//       setMessages([...messages,
//         { text, isBot: false},
//         { text: res, isBot: true},
//         { text: "Would you like a list of practice questions?", isBot: true}])
//         setFirstTime(false);
//       } else {
//         if (text.includes("ye") && practiceQuestionsTriggered) {
//           const newString = "Let's start a Q&A Session. Ask me a practice question regarding " + knowledgelevel
//           console.log(newString)
//           const res = await sendMsgToOpenAI(newString);
//           setMessages([...messages,
//             { text, isBot: false},
//             { text : res, isBot: true}]);
//           }
//         else {
//           const res = await sendMsgToOpenAI(text + "Tell me if I am correct. What's your next question?");
//           setMessages([...messages,
//             { text, isBot: false},
//             { text : res, isBot: true}]);
//         }
//         setTrigger(false);
//       }
//     } catch(error) {
//       console.error(error);
//     }
//   }

//   console.log(knowledgelevel);

//   const handleEnter = async (e) => {
//     if (e.key ==='Enter') await handleSend();
//   }

//   const handleQuery = async(e) => {
//     const text = e.target.value;
//     setMessages([
//       ...messages,
//       {text, isBot: false}
//     ])
//     const res = await sendMsgToOpenAI(text);
//     setMessages([...messages,
//       { text, isBot: false},
//       { text : res, isBot: true}])
//       console.log(messages)
//   };

//   return (
//   <div className="App">
//     <div className="sideBar">
//       <div className="upperSide">
//         <div className="upperSideTop"><img src={gptLogo} alt="Logo" className="logo" /><span className="brand">Lenor</span></div>
//         <button className="midBtn" onClick={()=>{window.location.reload()}}><img src={addBtn} alt="new chat" className="addBtn" />New Chat</button>
//         <div className="upperSideBottom">
//           <button className="query" onClick={handleQuery} value = "What is Programming?"><img src={msgIcon} alt="Query" />What is Programming?</button>
//           <button className="query" onClick={handleQuery} value = "How to use an API?"><img src={msgIcon} alt="Query" />How to use an API?</button>
//           <button className="query" onClick={handleQuery} value = "What is your level of knowledge on this subject?"><img src={msgIcon} alt="Query" />What is your level of knowledge on this subject?Beginner, Intermediate, Advanced? </button>
//         </div>
//     </div>
//     <div className="lowerSide">
//       <div className="listItems"><img src={home} alt="" className="listItemsImg"/>Home</div>
//       <div className="listItems"><img src={saved} alt="" className="listItemsImg"/>Saved</div>
//       <div className="listItems"><img src={rocket} alt="" className="listItemsImg"/>Upload a File</div>
//     </div>
//     </div>
//   <div className="main">
//     <div className="chats">
//       {messages.map((message, i) => 
//         <div key={i} className={message.isBot?"chat bot":"chat"}>
//           <img className='chatImg' src={message.isBot?gptImgLogo:userIcon} alt="" /><p className="txt">{message.text}</p>
//         </div>
//       )}
//       <div ref={msgEnd}></div>
//     </div>
//     <div className="chatFooter">
//       <div className="inp">
//       <input type="text" placeholder='Send a Message' value={input} onKeyDown={handleEnter} onChange={(e) => { setInput(e.target.value)}}/>
//       <button className="send" onClick={handleSend}><img src={sendBtn} alt="Send" /></button>

//       </div>
//       <p>Lenor may produce incorrect information about people, places or facts.</p>
//       <p>This website is best viewed on a desktop browser.</p>
//     </div>
//   </div>
// </div>

//   );
// }

// export default App;